<template>
  <div class="ResetPasswordView">
    <div class="o-container-gray">
      <div v-if="isResetPasswordLoading">
        <h3 class="u-justify-content-center u-text-center">{{ $t('resetPasswordView.resetPasswordInProgress') }}</h3>
        <Loader />
      </div>
      <div v-else>
        <div v-if="resetPasswordRequestSent">
          <img src="@/assets/images/checkmark.png"
               alt="Checkmark image"
               class="o-image-medium" />
          <h3 class="o-default-h3">{{ $t('resetPasswordView.passwordSuccessfullyChanged') }}</h3>
          <h4 class="o-default-h4">{{ $t('resetPasswordView.confirmationEmailSent') }}</h4>
          <md-button class="md-raised md-primary navigate-home-btn u-display-block"
                     @click="Helpers.goToRoute('', $router)">
            {{ $t('resetPasswordView.mainPage') }}
          </md-button>
          <md-button class="md-raised navigate-account-btn u-display-block"
                     @click="Helpers.goToRoute('my-account', $router)">
            {{ $t('resetPasswordView.myAccount') }}
          </md-button>
        </div>
        <div v-else>
          <form novalidate class="md-layout" @submit.prevent="validatePassword">
            <md-card>
              <md-progress-bar md-mode="indeterminate" v-if="isAuthLoading" />
              <md-card-header>
                <div class="md-title">{{ $t('resetPasswordView.title') }}</div>
              </md-card-header>

              <md-card-content>
                <md-field :class="getValidationClass('password')">
                  <label>{{ $t('resetPasswordView.password') }}</label>
                  <md-input v-model="password"
                            type="password"
                            name="password"
                            id="password">
                  </md-input>
                  <span class="md-error" v-if="!$v.password.required">{{ $t('common.auth.enterYourPassword') }}</span>
                  <span class="md-error" v-else-if="!$v.password.minLength">{{ $t('resetPasswordView.minLengthPassword') }}</span>
                </md-field>

                <md-field :md-toggle-password="false" :class="getValidationClass('passwordConfirmation')">
                  <label>{{ $t('resetPasswordView.confirmPassword') }}</label>
                  <md-input v-model="passwordConfirmation"
                            type="password"
                            name="passwordConfirmation"
                            id="passwordConfirmation" />
                  <span class="md-error" v-if="!$v.passwordConfirmation.required">{{ $t('resetPasswordView.enterPasswordAgain') }}</span>
                  <span class="md-error" v-else-if="!$v.passwordConfirmation.sameAsPassword">{{ $t('resetPasswordView.passwordsMustBeIdentical') }}</span>
                </md-field>
              </md-card-content>

              <div v-if="errors" v-html="errors" class="u-default-body u-red"></div>

              <vue-recaptcha ref="invisibleRecaptcha"
                             @verify="onVerify"
                             @expired="onExpired"
                             size="invisible"
                             :sitekey="sitekey">
              </vue-recaptcha>

              <md-card-actions class="u-justify-content-center u-text-center">
                <md-button type="submit" class="md-raised md-primary send-btn">{{ $t('common.continue') }}</md-button>
              </md-card-actions>
            </md-card>

            <!-- <md-snackbar :md-active.sync="userSaved">The user {{ lastUser }} was saved with success!</md-snackbar> -->
          </form>
        </div>
      </div>
    </div> <!-- /.o-container-gray -->

    <p class="o-default-body">{{ $t('forgotPasswordView.ifIssuesEncountered') }} {{ $t('contactEmailInreg') }}</p>

    <SecureLock />
  </div> <!-- /.ResetPasswordView -->
</template>

<script>
import { mapState } from 'vuex';
import {
  START_AUTH_LOADER,
  RESET_PASSWORD,
  RESET_AUTH_ERROR
} from '@/store/actions.type';
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  sameAs
} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import SecureLock from '@/components/util/SecureLock';
import Loader from '@/components/util/Loader';


export default {
  name: 'ResetPasswordView',
  mixins: [validationMixin],
  components: {
    Loader,
    VueRecaptcha,
    SecureLock
  },
  data() {
    return {
      resetPasswordToken: null,
      password: '',
      passwordConfirmation: '',
      resetPasswordRequestSent: false,
      snackbar: {
        show: false,
        position: 'center',
        duration: 4000,
        isInfinity: false
      },
      sitekey: '6LehkZMUAAAAAOua6fmkshH_FUe0rkw0v45UXjIS'
    };
  },
  created() {
    this.resetPasswordToken = this.$route.query.resetPasswordToken;
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthLoading: state => state.auth.isAuthLoading,
      isResetPasswordLoading: state => state.auth.isResetPasswordLoading
    })
  },
  mounted() {
    this.resetErrors();
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.password = null
      this.passwordConfirmation = null
    },
    validatePassword() {
      this.$v.$touch()

      this.$refs.invisibleRecaptcha.execute();
    },
    sendResetPasswordRequest(response) {
      this.$store.dispatch(START_AUTH_LOADER);

      this.$store
        .dispatch(RESET_PASSWORD, {
          password: this.password,
          resetPasswordToken: this.resetPasswordToken,
          captchaToken: response
        })
        .then(() => {
          this.resetPasswordRequestSent = true;
        });
    },
    onVerify(response) {
      this.$refs.invisibleRecaptcha.reset();

      if (!this.$v.$invalid) {
        this.sendResetPasswordRequest(response);
      }
    },
    onExpired() {
      this.$refs.invisibleRecaptcha.reset();

      // eslint-disable-next-line
      console.log('Expired');
    },
    resetErrors() {
      this.$store.dispatch(RESET_AUTH_ERROR);
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs('password'),
    },
  },
};
</script>

<style lang="scss" scoped>
.ResetPasswordView {

  .md-card {
    margin-left: auto;
    margin-right: auto;
    padding: 1.6rem;
    width: 66rem;
  }

  .md-field:last-child {
    margin-bottom: 2.4rem;
  }

  .o-container-gray {
    margin-top: 0;
  }

  .o-default-h3,
  .o-default-h4 {
    text-align: center;
  }

  .navigate-to-main-page {
    margin-top: 3.2rem;
  }

  .navigate-home-btn {
    background-color: $palette-primary-color-four !important;
    border-radius: .4rem;
    box-shadow: none;
    font-family: $font-family-heading-regular;
    height: 3.8rem;
    letter-spacing: .1rem;
    margin: 1.6rem auto;
    text-transform: none;
  }

  .navigate-account-btn {
    margin: 1.6rem auto;
    text-transform: none;
  }

  .send-btn {
    background-color: $palette-primary-color-four !important;
    width: 100%;
  }
}
</style>
